import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { ToolTip } from "common-components";
import FooterComponent from "../CommonComponents/FooterComponent";
import Subheader from "../Subheader/Subheader";

const calendarImg = require("../../images/chna_splash_calendar.png");
const chnaButtonImg = require("../../images/chna_splash_button.png");

class ChnaSplash extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { xOrigin, yOrigin, isToolTipVisible, text } = this.state;
    return (
      <div>
        <Helmet>
          <meta
            name="description"
            content="CHNA Community Health Needs Assessment by BroadStreet"
          />

          <meta property="og:locale" content="en_US" />
          <meta property="og:site_name" content="BroadStreet" />
          <meta property="og:title" content="CHNA - BroadStreet" />
          <meta
            property="og:description"
            content="CHNA Community Health Needs Assessment by BroadStreet"
          />
          <meta
            property="og:url"
            content="https://demo.broadstreet.io/board/chna"
          />
          <meta itemprop="name" content="BroadStreet CHNA" />

          <meta name="twitter:title" content="CHNA - BroadStreet" />
          <meta
            name="twitter:description"
            content="CHNA Community Health Needs Assessment by BroadStreet"
          />
          <meta
            name="twitter:image:src"
            content="https://demo.broadstreet.io/images/BroadStreet_Logo2.png"
          />

          <title>CHNA Community Health Needs Assessment by BroadStreet</title>
        </Helmet>
        <div className="projectBoard-right-component">
          <div className="project-board-home-wrapper container">
            <Subheader />
            <div>
              <div>
                <div className="project-board-home-wrapper container mt-5">
                  <img
                    src={calendarImg}
                    alt="Community Indicator Reports Coming In March 2019"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://engagementnetwork.org/assessment/"
                    title="Run a Community Indicator Report"
                  >
                    <img
                      src={chnaButtonImg}
                      alt="Run a Community Indicator Report"
                    />
                  </a>
                  <ToolTip
                    x={xOrigin}
                    y={yOrigin}
                    isVisible={isToolTipVisible}
                    text={text}
                  />
                </div>
              </div>
              <FooterComponent />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChnaSplash;
