import React, { Component } from 'react';
import { QueryRenderer, fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import '../../css/components/Card/ServiceAreaMapCardContainer.css';
import withCardContainer from './CardContainer';
import { withRouter } from 'react-router';
import { ButtonComponent } from 'common-components';
import { arrayOfMapPlaceholders } from '../../utility/utilities';
import MoreOptionPopup from '../CommonComponents/MoreOptionPopup';

class ServiceAreaMapCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = ({
      mapPlaceholder: (props.id.charCodeAt(props.id.length - 1) + props.id.charCodeAt(props.id.length - 2)) % arrayOfMapPlaceholders.length,
      isMorePopUpVisible: false,
      cardCount: {
        mapCardsCount: 0,
        storiesCount: 0
      }
    });
    this.maps = React.createRef();
  }

  handleClick = () => {
    const {isEditingEnabled, handleWideCardVisible, fromPage, onSelectArea, setSelectedCardForMutation, data} = this.props;
    const {data: {boardCardUse: {configuration}}} = this.props;
    if (fromPage === 'areas' && onSelectArea && configuration && configuration.savedArea) {
      onSelectArea(configuration.savedArea.id);
      setSelectedCardForMutation(data.boardCardUse.id);
    } else if (isEditingEnabled !== true && handleWideCardVisible !== undefined) {
      handleWideCardVisible(data.boardCardUse.id);
    }
  };

  componentWillReceiveProps(nextProps) {
    const {areaSelected, isAreaSelectorVisible} = this.props;
    if (nextProps.isClickOutside) {
      this.setState((state) => {
        state.isMorePopUpVisible = false;
        return state;
      });
    }
    if (nextProps.isAreaSelectorVisible !== undefined &&
      areaSelected &&
      areaSelected === nextProps.data.boardCardUse.configuration.savedArea.id &&
      !nextProps.isAreaSelectorVisible && isAreaSelectorVisible) {
      this.handleUpdateBoard(true);
    }
  }

  openDeleteMenu = (e, savedArea) => {
    e.stopPropagation();
    e.preventDefault();
    const {isMorePopUpVisible} = this.state;
    if (savedArea) {
      const mutation = graphql`
            query ServiceAreaMapCardContainerCountQuery($input: ID!) {
              savedArea(id: $input) {
                id
                mapCardsCount
                storiesCount
              }
            }
        `;

      const variables = {input: savedArea.id};
      fetchQuery(environment,
        mutation,
        variables)
        .then((response, errors) => {
          this.setState((state) => {
            state.isMorePopUpVisible = !state.isMorePopUpVisible;
            state.cardCount = {
              mapCardsCount: response.savedArea.mapCardsCount,
              storiesCount: response.savedArea.storiesCount
            };
            return state;
          });
        })
        .catch(err => console.error(err));
    } else {
      this.setState({isMorePopUpVisible: !isMorePopUpVisible});
    }
  };

  onDeleteClick = () => {
    const {deleteButtonClick, data: {boardCardUse: {id}}} = this.props;
    const {cardCount} = this.state;
    this.setState((state) => {
      state.isMorePopUpVisible = false;
      return state;
    }, () => {
      deleteButtonClick(id, cardCount);
    });
  };

  onDeleteAndReplaceClick = () => {
    const {deleteAndReplaceButtonClick, data: {boardCardUse: {id}}} = this.props;
    const {cardCount} = this.state;
    this.setState((state) => {
      state.isMorePopUpVisible = false;
      return state;
    }, () => {
      deleteAndReplaceButtonClick(id, cardCount);
    });
  };

  handleUpdateBoard = (refreshPage = false) => {
    this.setState((prevState) => {
      return {
        isUpdateBoard: !prevState.isUpdateBoard,
        refreshPage
      };
    });
  };

  handleAreaTallModalPopUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {data, showCardAreaPopUp} = this.props;
    const savedAreaId = data && data.boardCardUse.configuration.savedArea;
    showCardAreaPopUp(data.boardCardUse.id, savedAreaId);
  };

  showAreaToolTip = () => {
    const {showToolTip, fromPage} = this.props;
    const bounds = this.maps ? this.maps.current.getBoundingClientRect() : null;
    if (fromPage === 'areas') {
      showToolTip(bounds.x + 90, bounds.y + 30, 'Edit');
    } else {
      showToolTip(bounds.x + 80, bounds.y + 30, 'Select New Area');
    }
  };

  render() {
    const {fromPage, isEditingEnabled, isPublishedStory, data, isMapVisible, id, hideToolTip} = this.props;
    const {refreshPage, isUpdateBoard, mapPlaceholder, isMorePopUpVisible} = this.state;
    const areaTag = data && data.boardCardUse && data.boardCardUse.configuration.savedArea.name;
    const titleLength = data.boardCardUse.configuration.title != null ?
      data.boardCardUse.configuration.title.length : 0;
    return (
      <div>
        <div
          className={isEditingEnabled ? 'serviceAreaMapCardContainer movable' : 'serviceAreaMapCardContainer clickable'}
          onClick={this.handleClick}>
          {isMapVisible === true ?
            <QueryRenderer
              dataFrom={refreshPage ? 'STORE_THEN_NETWORK' : ''}
              environment={environment}
              query={graphql`
                                        query ServiceAreaMapCardContainerQuery($input: ID!)
                                        {
                                          boardCardUse(id: $input) {
                                            id
                                            mapImage
                                            statistics
                                            {
                                              id
                                              jsonData
                                            }
                                            configuration {
                                              savedArea {
                                                id
                                                name
                                                typeOfArea
                                                reportstatisticsSet {
                                                  edges {
                                                    node {
                                                      id
                                                      saZctaCount
                                                      saGeo
                                                      saStateList
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                    `}
              variables={{isUpdateBoard: isUpdateBoard, input: id}}
              render={({error, props}) => {
                if (error) {
                  console.error(error);
                  return <div>Error!</div>;
                }
                if (!props) {
                  return <div>
                    <div className="serviceAreaMapCardContainer-map"><br/><br/>Loading...</div>
                  </div>;
                }
                // console.log("project board ------> ", JSON.stringify(props))
                if (props.boardCardUse == null) {
                  return <div><br/>You need to log in as a user with permissions to access this
                    content.</div>;
                }
                const sa = props.boardCardUse.configuration.savedArea;
                if (sa == null || (sa.reportstatisticsSet && !sa.reportstatisticsSet.edges.length)) {
                  return <div><br/>(Map Not Available)</div>;
                }
                //const rs = sa.reportstatisticsSet.edges[0].node;
                //const counties = sa.counties.edges;
                //const places = sa.places.edges;
                //const zipcodes = sa.zipcodes.edges.map((item) => { return
                // item.node.zcta.geojsonFeature }); return item.node.zcta.geojsonFeature });
                // console.log("serviceArea", sa); console.log("counties", counties);
                // console.log("places", places); console.log("zipcodes", zipcodes);
                return (
                  <div>
                    <div className="serviceAreaMapCardContainer-map">
                      <img alt=""
                           src={'https://demo.broadstreet.io' + props.boardCardUse.mapImage}
                           onError={(e) => {
                             e.target.src = arrayOfMapPlaceholders[mapPlaceholder];
                             e.target.height = 200;
                             e.target.onerror = null;
                           }}
                      />
                      <div className="mapboxgl-ctrl-bottom-right">
                        <div className="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-compact">
                          <div className="mapboxgl-ctrl-attrib-inner">
                            <a href="https://www.maptiler.com/copyright/" target="_blank"
                               rel="noopener noreferrer">©
                              MapTiler </a>
                            <a href="https://www.openstreetmap.org/copyright"
                               target="_blank" rel="noopener noreferrer">© OpenStreetMap
                              contributors</a>
                          </div>
                        </div>
                      </div>
                      <div className="serviceAreaMapCardContainer-watermrk-img">
                        <img src={require('../../images/favicon1.svg')} alt="BroadStreet"/>
                      </div>
                    </div>
                    <div
                      className={titleLength > 70 ? 'serviceAreaMapCardContainer-title long-story-title' : 'serviceAreaMapCardContainer-title'}>
                      {/*<p className={"text-center"}>{sa.name}</p>*/}
                      <div
                        className={'text-center my-2 d-flex justify-content-center align-items-center'}
                        onMouseOver={(fromPage === 'areas' || (!isEditingEnabled && isPublishedStory)) && this.showAreaToolTip}
                        onMouseOut={(fromPage === 'areas' || (!isEditingEnabled && isPublishedStory)) && hideToolTip}
                        ref={this.maps}>
                        {(!isEditingEnabled || fromPage === 'areas') ? <ButtonComponent
                            buttonType={(fromPage === 'areas' || (!isEditingEnabled && isPublishedStory)) ? 'select' : isEditingEnabled ? '' : 'link'}
                            className={'btn-dashed'}
                            isShuffleModeOn={isEditingEnabled}
                            isAreaTag={true}
                            isFromSavedAreas={fromPage === 'areas' && true}
                            iconClass={fromPage === 'areas' ? 'icon-pen' : (!isEditingEnabled && isPublishedStory) ? 'icon-arrow-down' : ''}
                            buttonTitle={areaTag ? areaTag : 'United States'}
                            onClick={(!isEditingEnabled && isPublishedStory) ? this.handleAreaTallModalPopUp : this.handleClick}
                          /> :
                          <p className={'text-center'}>{sa.name ? sa.name : 'United States'}</p>}
                      </div>
                    </div>
                    {fromPage === 'areas' &&
                    <div className="btn-container">
                      <div className="d-flex">
                        <ButtonComponent
                          iconClass="icon-edit mx-2"
                          buttonType="link"
                          className={'card-btn'}
                          onClick={() => this.handleClick()}/>
                        <ButtonComponent
                          iconClass="icon-bin mx-2"
                          buttonType="link"
                          className={'card-btn ml-1'}
                          onClick={(e) => this.openDeleteMenu(e, sa)}/>
                      </div>
                      <MoreOptionPopup x={-55}
                                       y={-30}
                                       isDeleteOption={true}
                                       hidePopUp={this.hidePopUp}
                                       onDeleteClick={this.onDeleteClick}
                                       onDeleteAndReplaceClick={this.onDeleteAndReplaceClick}
                                       isVisible={isMorePopUpVisible}
                      />
                    </div>
                    }
                  </div>
                );
              }}
            />
            :
            <img src={arrayOfMapPlaceholders[mapPlaceholder]} alt=''/>
          }
        </div>
      </div>
    );
  }
}

export default withCardContainer(withRouter(ServiceAreaMapCardContainer));
