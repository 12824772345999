import * as $ from "jquery";
import React, { useState, useEffect, useRef } from "react";
import "../../css/components/CommonComponents/StorySettingsPopup.css";
import "../../css/components/CommonComponents/AddSectionPopUp.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import { StandardTallModalPopup } from "common-components";
import { apiBaseUrl } from "../../utility/utilities";

const storyLayout = [
  {
    label: "Simple Page",
    image: require("../../images/layoutPage.svg"),
    name: "MyPage",
  },
  {
    label: "Classic Article",
    image: require("../../images/layoutArticle.svg"),
    name: "MyCollection",
  },
];

const sharingOptions = [
  {
    name: "Edit",
    value: "edit",
  },
  {
    name: "View",
    value: "view",
  },
  {
    name: "-",
    value: "-",
  },
];

const viewingOptions = [
  {
    name: "View",
    value: true,
  },
  {
    name: "-",
    value: false,
  },
];

const StorySettingsPopUp = (props) => {
  const headerImageRef = useRef();
  const faviconImageRef = useRef();

  const {
    history,
    handleCopyCollection,
    disableCopyButton,
    handleDeleteStory,
    handleIsImagePopUpVisible,
    data,
    ownerData,
    userData,
    closePopUp,
    updatingLeftMenuOnCopyCollection,
    handleUpdateBoard,
    handleTemplateCreated,
    handleLoginPopUp,
    handleViewPublishedVersion,
    goBack: goToParent,
  } = props;

  useEffect(() => {
    if (history.location.hash === "#sharing-privacy") {
      changeCurrentPage("sharing-privacy");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.hash]);

  const isStoryOwner =
    data.board.owner == null
      ? ownerData.id ===
          userData.currentUser.edges[0].node.boardauthorSet.edges[0].node.id ||
        false
      : data.board.owner.boardauthorSet.edges[0].node.id ===
          userData.currentUser.edges[0].node.boardauthorSet.edges[0].node.id ||
        false;

  const allowCopy = (data.board && data.board.allowCopy) === true;

  const [selectedValue, setSelectedValue] = useState(
    (data.board && data.board.boardType) || "MyCollection"
  );
  const [isWorking, setIsWorking] = useState(false);
  const [wasError, setWasError] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [isWorkingPrivacy, setIsWorkingPrivacy] = useState(false);
  const [wasErrorPrivacy, setWasErrorPrivacy] = useState(false);
  const [wasSavedPrivacy, setWasSavedPrivacy] = useState(false);
  const [selectHeaderColor, setSelectHeaderColor] = useState(false);
  const [selectButtonColor, setSelectButtonColor] = useState(false);
  const [calledMethod, setCalledMethod] = useState("");
  const [timer, setTimer] = useState();
  const [buttonColor, setButtonColor] = useState(
    data && data.board && data.board.buttonColor
      ? data.board.buttonColor
      : "#008faa"
  );
  const [headerColor, setHeaderColor] = useState(
    data && data.board && data.board.headerColor
      ? data.board.headerColor
      : "#fff"
  );
  const [organization, setOrganization] = useState(
    data && data.board && data.board.organization
      ? data.board.organization
      : "BroadStreet"
  );
  const [pageUrl, setPageUrl] = useState(
    data && data.board && data.board.permaLink
      ? data.board.permaLink.replace(/\/my\//, "")
      : ""
  );
  const [headerImage, setheaderImage] = useState(
    data && data.board && data.board.headerImage
      ? data.board.headerImage.url
      : apiBaseUrl + "images/storyLogo.svg"
  );
  const [headerImageId, setHeaderImageId] = useState(
    data && data.board && data.board.headerImage
      ? data.board.headerImage.id
      : null
  );
  const [imageName, setImageName] = useState();
  const [faviconImageId, setfaviconImageId] = useState(
    data && data.board && data.board.faviconImage
      ? data.board.faviconImage.id
      : null
  );
  const [faviconImage, setFaviconImage] = useState(
    data && data.board && data.board.faviconImage
      ? data.board.faviconImage.url
      : require("../../images/favicon1.svg")
  );
  const [logoLink, setLogoLink] = useState(
    data && data.board && data.board.logoLink
      ? data.board.logoLink
      : "demo.broadstreet.io"
  );
  const [isPermaLinkDuplicate, setIsPermaLinkDuplicate] = useState();

  // FUNCTIONS RELATED TO COLOR AND BRAND MODAL

  const showColorPicker = (colorFor) => {
    if (colorFor === "header") {
      setSelectHeaderColor(true);
    } else if (colorFor === "button") {
      setSelectButtonColor(true);
    }
  };

  const onAccept = (arg) => {
    setSelectHeaderColor(false);
    setSelectButtonColor(false);
  };

  const handleClose = () => {
    setSelectHeaderColor(false);
    setSelectButtonColor(false);
  };

  const onCancel = (colorFor) => (arg) => {
    if (colorFor === "header") {
      setHeaderColor(data && data.board ? data.board.headerColor : "#fff");
    } else {
      setButtonColor(data && data.board ? data.board.buttonColor : "#fff");
    }
    handleClose();
  };

  const onChangeColor = (colorFor) => (color) => {
    if (colorFor === "header") {
      setHeaderColor(color.hex);
    } else if (colorFor === "button") {
      setButtonColor(color.hex);
    }
    changeSaveState();
  };

  const addHeaderImage = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setheaderImage(reader.result);
        setImageName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (headerImage.match('data:image\\/([a-zA-Z]*);base64,([^\\"]*)')) {
      fetch(headerImage)
        .then((res) => res.blob())
        .then((blob) => {
          handleUploadImage(
            { 0: new File([blob], "ProjectImg.png", { type: "image/png" }) },
            "header"
          );
          changeSaveState();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerImage, imageName]);

  const handleUploadImage = (file, imageList) => {
    const mutation = graphql`
      mutation StorySettingsPopUpImageMutation($input: UploadBoardImageInput!) {
        uploadBoardImage(input: $input) {
          success
          boardImageNode {
            id
            name
            url
          }
        }
      }
    `;
    var uploadables = null;
    if (file != null) {
      uploadables = file;
    }

    const variables = {
      input: {
        file: null,
        imageList: imageList,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      uploadables,
      onCompleted: (response, errors) => {
        if (imageList === "favicon") {
          setfaviconImageId(response.uploadBoardImage.boardImageNode.id);
          setFaviconImage(response.uploadBoardImage.boardImageNode.url);
        } else {
          setHeaderImageId(response.uploadBoardImage.boardImageNode.id);
          setheaderImage(response.uploadBoardImage.boardImageNode.url);
        }
      },
      onError: (err) => {
        console.error("image upload look settings ", err);
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          if (imageList === "favicon") {
            setFaviconImage(
              data && data.board && data.board.faviconImage
                ? data.board.faviconImage.url
                : ""
            );
          } else {
            setheaderImage(
              data && data.board && data.board.headerImage
                ? data.board.headerImage.url
                : apiBaseUrl + "images/storyLogo.svg"
            );
          }
          handleLoginPopUp();
        }
      },
    });
  };

  const addFaviconImage = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setFaviconImage(reader.result);
        setImageName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (faviconImage.match('data:image\\/([a-zA-Z]*);base64,([^\\"]*)')) {
      fetch(faviconImage)
        .then((res) => res.blob())
        .then((blob) => {
          handleUploadImage(
            { 0: new File([blob], "ProjectImg.png", { type: "image/png" }) },
            "favicon"
          );
          changeSaveState();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faviconImage, imageName]);

  const handleSave = () => {
    setIsWorking(true);
    setWasSaved(false);
    setWasError(false);
    const mutation = graphql`
      mutation StorySettingsPopUpMutation($input: UpdateBoardInput!) {
        updateBoard(input: $input) {
          boardNode {
            id
            name
            subtitle
            permaLink
          }
          isPermaLinkDuplicate
        }
      }
    `;
    const variables = {
      input: {
        boardId: data.board.id,
        buttonColor,
        headerColor,
        headerImageId: headerImageId,
        faviconImageId: faviconImageId,
        logoLink,
        organization,
        permaLink: "/my/" + pageUrl.replace(/\//g, ""),
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (response.updateBoard.isPermaLinkDuplicate) {
          setIsWorking(false);
          setWasSaved(false);
          setWasError(true);
          setIsPermaLinkDuplicate(true);
          return;
        }
        updatingLeftMenuOnCopyCollection();
        handleUpdateBoard(true);
        setIsWorking(false);
        setWasSaved(true);
        setWasError(false);
        setIsPermaLinkDuplicate(response.updateBoard.isPermaLinkDuplicate);
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          handleLoginPopUp();
          setIsWorking(false);
          setWasError(false);
          setWasSaved(false);
        } else {
          setIsWorking(false);
          setWasError(true);
          setWasSaved(false);
        }
      },
    });
  };

  // END
  const copyPrivateLink = () => {
    const pages = [...pagesArray];
    const collaboratorsPage = pages[3];
    collaboratorsPage.buttonTitle = "Copied";
    collaboratorsPage.buttonState = "saved";
    setPagesArray(pages);
    navigator.clipboard.writeText($("#PrivateLink").val());
    $("#PrivateLink").focus().select();
  };

  const setCollaboratorsModal = (key) => {
    const pages = [...pagesArray];
    const collaboratorsPage = pages[3];
    collaboratorsPage.props.visibleModal = { [key]: true };
    switch (key) {
      case "collaborators":
        collaboratorsPage.title = "Collaborators";
        collaboratorsPage.showButton = true;
        collaboratorsPage.buttonTitle = "Add";
        collaboratorsPage.buttonState = null;
        collaboratorsPage.handleOnClick = () =>
          setCollaboratorsModal("addCollaborators");
        break;
      case "addCollaborators":
        collaboratorsPage.title = "Add Collaborators";
        collaboratorsPage.showButton = false;
        break;
      case "sharePrivateLink":
        collaboratorsPage.title = "Share a Private Link";
        collaboratorsPage.showButton = true;
        collaboratorsPage.buttonTitle = "Copy";
        collaboratorsPage.buttonState = null;
        collaboratorsPage.handleOnClick = () => copyPrivateLink();
        break;
      case "inviteViaEmail":
        collaboratorsPage.title = "Invite via Email";
        collaboratorsPage.showButton = false;
        break;
      default:
        break;
    }
    setPagesArray(pages);
  };

  const changeCurrentPage = (name) => {
    if (name === "collaborators") {
      setCollaboratorsModal("collaborators");
    }
    const page = pagesArray.find((page) => page.name === name);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (
      !isStoryOwner &&
      history &&
      history.location &&
      history.location.hash === "#privacy"
    ) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRadioVal = (e) => {
    setSelectedValue(e.currentTarget.value);
    changeSaveState();
  };

  const goBack = () => {
    switch (currentPage.name) {
      case "story-layout":
      case "colors-brand":
        changeCurrentPage("layout-look");
        break;
      case "collaborators":
        if (currentPage.props.visibleModal.collaborators) {
          if (history?.location?.hash === "#privacy") {
            changeCurrentPage("sharing-privacy");
          } else {
            changeCurrentPage("story-settings");
          }
        } else if (currentPage.props.visibleModal.addCollaborators) {
          setCollaboratorsModal("collaborators");
        } else {
          setCollaboratorsModal("addCollaborators");
        }
        break;
      case "layout-look":
      case "save-as-a-template":
      case "sharing-privacy":
        if (history?.location?.hash === "#sharing-privacy") {
          goToParent();
        } else {
          changeCurrentPage("story-settings");
        }
        break;
      default:
        closePopUp();
    }
  };

  const changeSaveState = () => {
    if (wasSaved) {
      if (timer) {
        clearTimeout(timer);
      }
      setWasSaved(false);
    }
  };

  const changeSaveStatePrivacy = () => {
    if (wasSavedPrivacy) {
      if (timer) {
        clearTimeout(timer);
      }
      setWasSavedPrivacy(false);
    }
  };

  const handleSaveStoryAsTemplate = () => {
    const mutation = graphql`
      mutation StorySettingsPopUpSaveTemplateMutation(
        $input: CreateBoardTemplateInput!
      ) {
        createBoardTemplate(input: $input) {
          boardNode {
            id
            name
            subtitle
            publishedStoryCard {
              id
              jsonid
            }
          }
        }
      }
    `;
    const variables = {
      input: {
        boardId: data.board.id,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        updatingLeftMenuOnCopyCollection();
        handleUpdateBoard(true);
        handleTemplateCreated(
          response.createBoardTemplate.boardNode.publishedStoryCard.jsonid
        );
        setIsWorking(false);
        setWasSaved(true);
        setWasError(false);
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          handleLoginPopUp();
        }
        setIsWorking(false);
        setWasError(false);
        setWasSaved(false);
        setIsWorkingPrivacy(false);
        setWasErrorPrivacy(false);
        setWasSavedPrivacy(false);
      },
    });
  };

  useEffect(() => {
    if (!isWorking && wasSaved && !wasError) {
      closePopUp();
      if (calledMethod === "handleSaveStoryLayout") {
        const timer = setTimeout(() => {
          if (wasSaved) {
            setWasSaved(false);
          }
        }, 10000);
        setTimer(timer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWorking, wasSaved, wasError]);

  const findPrivacyPageItemValue = (name) => {
    const privacyPage = pagesArray.find(
      (obj) => obj.name === "sharing-privacy"
    );
    const { value } = privacyPage.formItems.find((obj) => obj.name === name);
    return value;
  };

  const handleSaveStoryLayout = (type = "layout") => {
    const collaboratorRole = findPrivacyPageItemValue("collaboratorRole");
    const invitationLinkRole = findPrivacyPageItemValue("invitationLinkRole");
    const publishAsPublic = findPrivacyPageItemValue("publishAsPublic");
    const publishOutsidePaywall = findPrivacyPageItemValue(
      "publishOutsidePaywall"
    );
    const allowCopy = findPrivacyPageItemValue("allowCopy");

    setIsWorkingPrivacy(true);
    const mutation = graphql`
      mutation StorySettingsPopUpLayoutMutation($input: UpdateBoardInput!) {
        updateBoard(input: $input) {
          boardNode {
            id
            name
            subtitle
          }
        }
      }
    `;
    const variables = {
      input: {
        boardId: data.board.id,
        boardType: selectedValue,
        collaboratorRole,
        allowCopy,
        invitationLinkRole,
        publishAsPublic,
        publishOutsidePaywall,
      },
    };
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (updatingLeftMenuOnCopyCollection) {
          updatingLeftMenuOnCopyCollection();
        }
        handleUpdateBoard(true);
        if (type === "Privacy") {
          setIsWorkingPrivacy(false);
          setWasSavedPrivacy(true);
          setWasErrorPrivacy(false);
        } else {
          setIsWorking(false);
          setWasSaved(true);
          setWasError(false);
          setCalledMethod("handleSaveStoryLayout");
        }
      },
      onError: (err) => {
        console.error(JSON.stringify(err));
        if (
          err.errors &&
          err.errors.length &&
          err.errors[0].message === "UserLoginRequired"
        ) {
          handleLoginPopUp();
        }
        setIsWorking(false);
        setWasError(false);
        setWasSaved(false);
        setIsWorkingPrivacy(false);
        setWasErrorPrivacy(false);
        setWasSavedPrivacy(false);
      },
    });
  };

  useEffect(() => {
    if (!isWorkingPrivacy && wasSavedPrivacy && wasErrorPrivacy) {
      const timer = setTimeout(() => {
        if (wasSavedPrivacy) {
          setWasSavedPrivacy(false);
        }
      }, 10000);
      setTimer(timer);
    }
  }, [isWorkingPrivacy, wasSavedPrivacy, wasErrorPrivacy]);

  const handleTypeChange = (e) => {
    let value = e.target.value;
    if (value === "true" || value === "false") {
      value = value === "true";
    }
    const pages = [...pagesArray];
    const privacyPage = pages.find((obj) => obj.name === "sharing-privacy");
    privacyPage.formItems.forEach((obj) => {
      if (obj.name === e.target.name) {
        obj.value = value;
      }
    });
    setPagesArray(pages);
    changeSaveStatePrivacy();
  };

  const pages = [
    {
      showTitle: true,
      showPageItem: false,
      title: "Story Settings",
      name: "story-settings",
      menuList: [
        {
          icon: "left-icon-drop",
          title: "Layout & Look",
          name: "layout-look",
          onClickItem: () => {
            changeCurrentPage("layout-look");
          },
        },
        {
          icon: "left-icon-man",
          title: "Collaborators",
          name: "collaborators",
          onClickItem: () => {
            changeCurrentPage("collaborators");
          },
        },
        {
          icon: "left-icon-lock",
          title: "Sharing & Privacy",
          onClickItem: () => {
            changeCurrentPage("sharing-privacy");
          },
        },
        {
          icon: "",
          title: "break",
        },
        {
          icon: "left-icon-view",
          title: "View Published Story",
          name: "view-published-story",
          onClickItem: () => {
            if (handleViewPublishedVersion) {
              handleViewPublishedVersion();
            }
          },
        },
        {
          icon: "left-icon-template",
          title: "Save as a Template",
          name: "save-as-a-template",
          hide: !allowCopy && !isStoryOwner,
          onClickItem: () => {
            changeCurrentPage("save-as-a-template");
          },
        },
        {
          icon: "left-icon-copy",
          title: "Make a Copy",
          name: "make-a-copy",
          showLoader: disableCopyButton,
          stopAnimation: true,
          hide: !allowCopy && !isStoryOwner,
          onClickItem: handleCopyCollection,
        },
        {
          icon: "left-icon-delete",
          title: "Delete",
          stopAnimation: true,
          onClickItem: handleDeleteStory,
        },
      ],
    },
    // LAYOUT AND LOOK MODAL
    {
      showTitle: true,
      showPageItem: false,
      title: "Layout and Look",
      name: "layout-look",
      menuList: [
        {
          icon: "left-icon-layout",
          title: "Story Layout",
          onClickItem: () => {
            changeCurrentPage("story-layout");
          },
        },
        {
          icon: "left-icon-media",
          title: "Featured Image",
          redirectTo: "#featuredLayout",
          onClickItem: () => {
            handleIsImagePopUpVisible(true);
          },
        },
        {
          icon: "left-icon-drop",
          title: "Colors & Brand",
          onClickItem: () => {
            changeCurrentPage("colors-brand");
          },
        },
      ],
    },
    // STORY LAYOUT MODAL
    {
      showTitle: true,
      showPageItem: false,
      title: "Story Layout",
      name: "story-layout",
      showButton: true,
      buttonTitle: "save",
      handleOnClick: () => handleSaveStoryLayout(),
      formContainerClass: "px-4",
      formItems: [
        {
          type: "string",
          text: "When the story is published, what layout would you like for it to have?",
          className: "text-muted",
        },
        {
          type: "radio",
          name: "optRadio",
          radioClassName: "mt-2",
          selectedValue,
          optionsArray: storyLayout,
          onChange: (e) => onChangeRadioVal(e),
        },
      ],
    },
    // COLLABORATORS MODAL
    {
      showTitle: true,
      showButton: true,
      buttonTitle: "ADD",
      title: "Collaborators",
      name: "collaborators",
      collaborators: true,
      props: {
        ...props,
        setCollaboratorsModal: setCollaboratorsModal,
      },
      handleOnClick: () => {
        setCollaboratorsModal("addCollaborators");
      },
    },
    // SHARING AND PRIVACY MODAL
    {
      showTitle: true,
      showPageItem: false,
      title: "Sharing & Privacy",
      name: "sharing-privacy",
      showButton: true,
      buttonTitle: wasSavedPrivacy
        ? "SAVED"
        : isWorkingPrivacy
        ? "SAVING"
        : "SAVE",
      handleOnClick: () => {
        handleSaveStoryLayout("Privacy");
      },
      modalCustomClass: "fs-16",
      formContainerClass: "row",
      formItems: [
        {
          type: "string",
          text: (
            <>
              People{" "}
              <a
                href="#privacy"
                onClick={() => changeCurrentPage("collaborators")}
              >
                invited via email
              </a>{" "}
              can
            </>
          ),
          fieldContainerClass: "col-8",
        },
        {
          type: "select",
          name: "collaboratorRole",
          value: (data.board && data.board.collaboratorRole) || "edit",
          fieldContainerClass: "col-4 mb-3",
          onChange: handleTypeChange,
          options: sharingOptions,
        },
        {
          type: "string",
          text: (
            <>
              People with{" "}
              <a
                href="#privacy"
                onClick={() => changeCurrentPage("collaborators")}
              >
                private link
              </a>{" "}
              can
            </>
          ),
          fieldContainerClass: "col-8",
        },
        {
          type: "select",
          name: "invitationLinkRole",
          value: (data.board && data.board.invitationLinkRole) || "edit",
          fieldContainerClass: "col-4 mb-3",
          onChange: handleTypeChange,
          options: sharingOptions,
        },
        {
          type: "string",
          text: "BroadStreet members can",
          fieldContainerClass: "col-8",
        },
        {
          type: "select",
          name: "publishAsPublic",
          value: (data.board && data.board.publishAsPublic) === true,
          fieldContainerClass: "col-4 mb-3",
          onChange: handleTypeChange,
          options: viewingOptions,
        },
        {
          type: "string",
          text: "Story is public. Anyone can",
          fieldContainerClass: "col-8",
        },
        {
          type: "select",
          name: "publishOutsidePaywall",
          value: (data.board && data.board.publishOutsidePaywall) === true,
          fieldContainerClass: "col-4 mb-3",
          onChange: handleTypeChange,
          options: viewingOptions,
        },
        {
          type: "string",
          text: "Story can be copied",
          fieldContainerClass: "col-12",
          className: "input-title",
        },
        {
          type: "string",
          text: 'Is this a story that people with "edit" permission can copy?',
          fieldContainerClass: "col-8",
        },
        {
          type: "select",
          name: "allowCopy",
          fieldContainerClass: "col-4 mb-3",
          onChange: handleTypeChange,
          options: [
            {
              name: "yes",
              value: true,
            },
            {
              name: "no",
              value: false,
            },
          ],
        },
      ],
    },
    // SAVE AS TEMPLATE MODAL
    {
      showTitle: true,
      showPageItem: false,
      title: "Save as a Template",
      name: "save-as-a-template",
      showButton: true,
      buttonTitle: "save",
      handleOnClick: handleSaveStoryAsTemplate,
      formContainerClass: "px-3",
      formItems: [
        {
          type: "string",
          text: "Save the current version as a template?",
          className: "input-title",
        },
        {
          type: "string",
          text: "A copy of the current version of this story will be saved under My Templates.",
          className: "text-muted",
        },
        {
          type: "string",
          text: (
            <div className="d-flex align-items-center">
              <img
                src={require("../../images/iconInfoDark.svg")}
                className="mr-2 mb-1"
                width={15}
                alt="Info"
              />
              <div>About Templates</div>
            </div>
          ),
          className: "input-title",
        },
        {
          type: "string",
          text: "A template can be used again and again to create many new stories.",
          className: "text-muted",
        },
      ],
    },
    // COLOR AND BRAND MODAL
    {
      showTitle: true,
      showPageItem: false,
      title: "Colors & Brand",
      name: "colors-brand",
      showButton: true,
      buttonTitle: isWorking ? "SAVING" : wasSaved ? "SAVED" : "SAVE",
      formContainerClass: "grid-2-col px-2",
      handleOnClick: handleSave,
      formItems: [
        {
          type: "string",
          text: "Organization",
          className: "input-title mb-2",
        },
        {
          type: "text",
          label: "What is the name of your organization?",
          labelClassName: "p-b-2",
          name: "organization",
          value: organization,
          onChange: (e) => {
            setOrganization(e.target.value);
            changeSaveState();
          },
          fieldContainerClass: "grid-1-3",
          className: "mb-4",
        },
        {
          type: "colorPicker",
          id: "buttonColor",
          name: "buttonColor",
          label: "Hyperlink Color",
          labelClassName: "p-b-2",
          defaultColor: buttonColor,
          fieldContainerClass: "grid-1-2 pr-3 mb-4",
          selectColor: selectButtonColor,
          onClick: () => {
            showColorPicker("button");
          },
          onAccept: onAccept,
          onCancel: onCancel("button"),
          onChangeComplete: onChangeColor("button"),
        },
        {
          type: "button",
          buttonTitle: "EDIT",
          buttonType: "outlined",
          handleOnClick: () => {
            showColorPicker("button");
          },
          fieldContainerClass: "grid-2-3 d-flex align-items-end pb-1 mb-4",
        },
        {
          type: "colorPicker",
          id: "headerColor",
          name: "headerColor",
          label: "Header Color",
          labelClassName: "p-b-2",
          defaultColor: headerColor,
          readOnly: true,
          fieldContainerClass: "grid-1-2 pr-3",
          selectColor: selectHeaderColor,
          onClick: () => {
            showColorPicker("header");
          },
          onAccept: onAccept,
          onCancel: onCancel("header"),
          onChangeComplete: onChangeColor("header"),
        },
        {
          type: "button",
          buttonTitle: "EDIT",
          buttonType: "outlined",
          handleOnClick: () => {
            showColorPicker("header");
          },
          fieldContainerClass: "grid-2-3 d-flex align-items-end pb-1",
        },
        {
          type: "string",
          text: "Select the color that you wish to appear in the header.",
          className: "text-muted",
          fieldContainerClass: "grid-1-2 mt-2",
        },
        {
          type: "string",
          text: "Story Url",
          className: "input-title mb-2",
          fieldContainerClass: "grid-1-3",
        },
        {
          type: "string",
          text: "https://demo.broadstreet.io/story/",
          className: "input-title mb-2",
          fieldContainerClass: "grid-1-3",
        },
        {
          type: "text",
          placeholder: "Fill in unique text",
          name: "pageUrl",
          value: pageUrl,
          onChange: (e) => {
            setPageUrl(e.target.value);
            changeSaveState();
          },
          fieldContainerClass: "grid-1-3",
          componentWrapperClass: "mb-2",
        },
        {
          type: "string",
          text:
            isPermaLinkDuplicate &&
            "The link you entered above is already used by another story.",
          fieldContainerClass: "grid-1-3",
          className: "text-danger mb-0 fs-15",
        },
        {
          type: "string",
          text: "When you publish a story, this is how the link will look.",
          fieldContainerClass: "grid-1-3",
          className: "text-muted",
        },
        {
          type: "string",
          text: "Logo",
          className: "input-title mb-2",
          fieldContainerClass: "grid-1-3",
        },
        {
          type: "image",
          component: (
            <>
              <div
                style={{ backgroundColor: headerColor ? headerColor : "#fff" }}
              >
                <label htmlFor={"fileUploadLS"} style={{ cursor: "pointer" }}>
                  <img src={headerImage} height={50} width={300} alt="" />
                </label>
              </div>
              <input
                id={"fileUploadLS"}
                ref={headerImageRef}
                accept="image/png,image/x-png,image/gif,image/jpeg"
                multiple={false}
                type="file"
                className={"hidden"}
                onChange={addHeaderImage}
                name="headerImage"
              />
            </>
          ),
          fieldContainerClass: "grid-1-2",
        },
        {
          type: "button",
          buttonTitle: "EDIT",
          buttonType: "outlined",
          handleOnClick: () => {
            headerImageRef.current.click();
          },
          fieldContainerClass: "grid-2-3 d-flex align-items-center pb-2",
        },
        {
          type: "string",
          className: "text-muted",
          text: "Your logo will appear on the header of your page. We recommend an SVG, PNG, or JPEG that fits in a 300x50 pixel area.",
          fieldContainerClass: "grid-1-2",
        },
        {
          type: "string",
          text: "Favicon",
          className: "input-title mb-2",
          fieldContainerClass: "grid-1-3",
        },
        {
          type: "image",
          component: (
            <>
              <label
                className={"text-center border p-3"}
                htmlFor={"fileUpload1"}
                style={{ cursor: "pointer" }}
              >
                <img src={faviconImage} height={30} width={30} alt="" />
              </label>
              <input
                id={"fileUpload1"}
                ref={faviconImageRef}
                accept="image/png,image/x-png,image/gif,image/jpeg"
                multiple={false}
                type="file"
                className={"hidden"}
                onChange={addFaviconImage}
                name="faviconImage"
              />
            </>
          ),
          fieldContainerClass: "grid-1-2",
        },
        {
          type: "button",
          buttonTitle: "EDIT",
          buttonType: "outlined",
          handleOnClick: () => {
            faviconImageRef.current.click();
          },
          fieldContainerClass: "grid-2-3 d-flex align-items-start",
        },
        {
          type: "string",
          className: "text-muted",
          text: "This is how your Favicon will appear in the left navigation menu. Image is a PNG that is 32x32 pixels.",
          fieldContainerClass: "grid-1-2",
        },
        {
          type: "text",
          label: "Logo Link",
          labelClassName: "p-b-2",
          name: "logoLink",
          value: logoLink,
          onChange: (e) => {
            setLogoLink(e.target.value);
            changeSaveState();
          },
          fieldContainerClass: "grid-1-3",
          className: "mb-4",
          helperText:
            "If someone clicks on your logo at the top of a story, what webpage do they go to?",
        },
      ],
    },
  ];

  const [pagesArray, setPagesArray] = useState(pages);
  const [currentPage, setCurrentPage] = useState(pagesArray[0]);

  useEffect(() => {
    setPagesArray(pages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedValue,
    headerColor,
    buttonColor,
    selectHeaderColor,
    selectButtonColor,
    organization,
    headerImage,
    faviconImage,
    logoLink,
    isWorking,
    wasSaved,
    disableCopyButton,
    pageUrl,
    isPermaLinkDuplicate,
  ]);

  return (
    <StandardTallModalPopup
      pages={pagesArray}
      closePopUp={closePopUp}
      goBack={goBack}
      currentPage={currentPage}
    />
  );
};

export default StorySettingsPopUp;
