import React, { Component } from 'react';
import { commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../RelayEnvironment';
import '../../css/styles.css';
import withCardContainer from './CardContainer';
import ReportImageCard from './ReportImageCard';
import acesFeatured from '../../images/acesfeatured3.png';
import adiFeatured from '../../images/adifeatured.png';
import chnaFeatured from '../../images/chnafeatured.png';
import foodFeatured from '../../images/foodfeatured1.png';
import demoSvg from '../../images/demfeatured.png';
import covidSvg from '../../images/covid19featured4.png';
import mortSvg from '../../images/mortfeatured2.png';
import stiSvg from '../../images/stifeatured.png';
import infantMortSvg from '../../images/infant-featured.png';
import violencePreventionSvg from '../../images/violencefeatured.png';
import suiprevSvg from '../../images/suiprevfeatured.png';
import mapRoom from '../../images/mapfeatured.png';
import makeMapSvg from '../../images/makemapfeatured.png';
import browseBlueSvg from '../../images/overviewfeatured.png';
import createStory from '../../images/createfeatured1.png';
import { ButtonComponent } from 'common-components';
import { connect } from 'react-redux';
import { startMembershipAction } from '../../actions/user';
const mutation = graphql`
  mutation ReportToolCardContainerMutation($input: CreateBoardInput!) {
    createBoard(input: $input) {
      boardNode {
        id
        name
      }
    }
  }
`;

class ReportToolCardContainer extends Component {
  onCardClick = (path, popupType) => {
    const { userData, handleLoginPopUp, history, startMembershipAction, subscriptionInfo } = this.props;
    const isFreeToolCard = (popupType === 'browseindicators' || popupType === 'EXPLOREMAPS' || popupType === 'map-room');
    if (isFreeToolCard || (subscriptionInfo && subscriptionInfo.isOverSubscriptionLimits === false)) {
      if (popupType === 'map-room') {
        window.open(`https://demo.broadstreet.io/map/`);
      } else if (popupType === 'CreateStory') {
        this.onCreatingNewBoard();
      } else {
        if (userData.currentUser.edges.length === 0 && popupType !== 'browseindicators') {
          handleLoginPopUp();
        } else {
          const state = {
            closeURL: window.location.pathname,
            popupType
          };
          history.push(path, state);
        }
      }
      startMembershipAction(false);
    } else {
      if (startMembershipAction) {
        startMembershipAction(true);
      }
    }
  };

  onCreatingNewBoard = () => {
    const { userData, handleLoginPopUp, history } = this.props;
    const variables = { input: { name: 'Untitled Story' } };
    if (userData.currentUser.edges.length === 0) {
      handleLoginPopUp();
    } else {
      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          console.log('response ', response);
          if (response && response.createBoard && response.createBoard.boardNode.id) {
            history.push('/BOARD/collection/' + response.createBoard.boardNode.id, 'new');
          }
        },
        onError: err => console.error(JSON.stringify(err))
      });
    }
  };

  getSubComponent = (data) => {
    // Each component will take 100% height and 100% width of its parent..
    const { subComponentType, isEditingEnabled } = this.props;
    
    switch (subComponentType) {
      case "acesToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "acesToolCard"}
            key={data.id + "acesToolCard"}
            link={"/choosearea/aces"}
            popupType={"RUNAREPORT"}
            icon={acesFeatured}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "A look at the burden of adverse childhood experiences (ACEs)."
            }
          />
        );
      }
      case "infantMortalityToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "infantMortalityToolCard"}
            key={data.id + "infantMortalityToolCard"}
            link={"/choosearea/infantmortality"}
            popupType={"RUNAREPORT"}
            icon={infantMortSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "A look at Our Community and the lives of the most vulnerable age group."
            }
          />
        );
      }
      case "covid19ToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "covid19ToolCard"}
            key={data.id + "covid19ToolCard"}
            link={"/choosearea/covid19"}
            popupType={"RUNAREPORT"}
            icon={covidSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={
              "BroadStreet is proud to be a founding member of The COVID-19 Data Project."
            }
          />
        );
      }
      case "demographicsToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "demographicsToolCard"}
            key={data.id + "demographicsToolCard"}
            link={"/choosearea/demographics"}
            popupType={"RUNAREPORT"}
            icon={demoSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"Basic information about who is living in our community."}
          />
        );
      }
      case "foodAssessmentCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "foodAssessmentCard"}
            key={data.id + "foodAssessmentCard"}
            link={"/choosearea/foodassessment"}
            popupType={"RUNAREPORT"}
            icon={foodFeatured}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"A view of food access, food security, and food deserts."}
          />
        );
      }
      case "mortalityToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "mortalityToolCard"}
            key={data.id + "mortalityToolCard"}
            link={"/choosearea/mortality"}
            popupType={"RUNAREPORT"}
            icon={mortSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"Trends in life expectancy and leading causes of death."}
          />
        );
      }
      case "areaDeprivationIndexCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "areaDeprivationIndexCard"}
            key={data.id + "areaDeprivationIndexCard"}
            link={"/choosearea/areadeprivationindex"}
            popupType={"RUNAREPORT"}
            icon={adiFeatured}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"Socioeconomic and housing vulnerability in our community."}
          />
        );
      }
      case "communityIndicatorReportCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "communityIndicatorReportCard"}
            key={data.id + "communityIndicatorReportCard"}
            link={"/choosearea/communityindicatoreport"}
            popupType={"RUNAREPORT"}
            icon={chnaFeatured}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"A basic starter report with indicators in our community."}
          />
        );
      }
      case "createNewStory": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "CreateNewStory"}
            key={data.id + "CreateNewStory"}
            link={"/choosearea/makeamap"}
            popupType={"CreateStory"}
            icon={createStory}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={""}
          />
        );
      }
      case "makeAMapCard" &&
        data.configuration.title === "Map Room": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "makeAMapCard"}
            key={data.id + "makeAMapCard"}
            link={"https://demo.broadstreet.io/map/"}
            popupType={"map-room"}
            icon={mapRoom}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"A quick link to our Map Room tool."}
          />
        );
      }
      case "makeAMapCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "makeAMapCard"}
            key={data.id + "makeAMapCard"}
            link={"/choosearea/makeamap"}
            popupType={"EXPLOREMAPS"}
            icon={makeMapSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"Build a map for your community by selecting an area."}
          />
        );
      }
      case "browseIndicatorsCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "browseIndicatorsCard"}
            key={data.id + "browseIndicatorsCard"}
            link={"/browseindicators"}
            popupType={"browseindicators"}
            icon={browseBlueSvg}
            onCardClick={!isEditingEnabled ? this.onCardClick : null}
            title={data.configuration.title}
            detail={"See what we’ve got. Search for fun."}
          />
        );
      }
      case "ToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "userToolCard"}
            key={data.id + "userToolCard"}
            link={"/choosearea/user" + data.id}
            popupType={"RUNAREPORT"}
            icon={data.configuration.thumbnailImage.url}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={data.configuration.subtitle}
          />
        );
      }
      case "stiToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "stiToolCard"}
            key={data.id + "stiToolCard"}
            link={"/choosearea/sti"}
            popupType={"RUNAREPORT"}
            icon={stiSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"A look at Sexually Transmitted Infections in Our Community."}
          />
        );
      }
      case "preventingViolenceToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "preventingViolenceToolCard"}
            key={data.id + "preventingViolenceToolCard"}
            link={"/choosearea/violence"}
            popupType={"RUNAREPORT"}
            icon={violencePreventionSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"Creating a Safe and Caring Community to Promote Peace."}
          />
        );
      }
      case "suicidePreventionToolCard": {
        return (
          <ReportImageCard
            {...this.props}
            id={data.id + "suicidePreventionToolCard"}
            key={data.id + "suicidePreventionToolCard"}
            link={"/choosearea/suiprev"}
            popupType={"RUNAREPORT"}
            icon={suiprevSvg}
            onCardClick={!isEditingEnabled && this.onCardClick}
            title={data.configuration.title}
            detail={"Hope for Another Sunrise."}
          />
        );
      }
      default:
        return null
    }
  };

  deleteTemplate = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { data, userData, handleDeleteCollectionPopUpVisible, handleRemoveSelfFromCollaborators, setSelectedCardForMutation } = this.props;
    if (data.boardCardUse.configuration.publishedStoryBoard) {
      const { sourceBoard: { id } } = data.boardCardUse.configuration.publishedStoryBoard;
      setSelectedCardForMutation(id);
    }
    if (data.boardCardUse.configuration.author) {
      const currentUserData = userData.currentUser.edges[0].node.boardauthorSet.edges[0].node;
      if (data.boardCardUse.configuration.author.id ===
        currentUserData.id) {
        handleDeleteCollectionPopUpVisible();
      } else {
        handleRemoveSelfFromCollaborators(currentUserData);
      }
    }
  };

  render() {
    const { fromPage, data } = this.props;
    return (
      <div className="designCardContainer">
        {data && this.getSubComponent(data.boardCardUse)}
        {fromPage === 'stories' &&
          <div className="btn-container">
            <div className="d-inline-block mt-2">
              <ButtonComponent
                iconClass="icon-bin"
                buttonType="link"
                className={'card-btn'}
                onClick={this.deleteTemplate} />
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapStatusToProps = (state) => ({
  subscriptionInfo: state.user.subscriptionInfo
});

const mapDispatchToProps = {
  startMembershipAction,
};

export default withCardContainer(connect(mapStatusToProps, mapDispatchToProps)(ReportToolCardContainer));
