import React, { Component } from "react";
import "../../css/components/SignInSignUpForgotPassword/SignInSignUpForgotPassword.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

class ForgotPasswordPopUpStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // name: '',
      email: props.match.params.email,
      password: "",
      cpassword: "",
      isLoginPopUpVisible: false,
      newMember: true,
      passwordResetComplete: false,
      isSignupPopUpVisible: false,
      // handleForgotPasswordPopUp: false,
      isEmailValid: false,
      isPasswordValid: false,
      isForgotPasswordStep2Called: false,
      matchPassword: false,
    };
  }

  handleClick(e) {
    const { password } = this.state;
    this.setState({ cpassword: e.target.value });
    if (password === e.target.value) {
      this.setState({ matchPassword: true });
    } else {
      this.setState({ matchPassword: false });
    }
  }

  forgotPassword(event) {
    const { match } = this.props;
    const { email, password } = this.state;
    event.preventDefault();
    this.setState({ isForgotPasswordStep2Called: true });
    const mutation = graphql`
      mutation ForgotPasswordPopUpStep2Mutation(
        $input: UpdatePasswordResetRequestInput!
      ) {
        updatePasswordReset(input: $input) {
          passwordResetRequestNode {
            resetStep
            userEmail
          }
          validationErrors {
            key
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        resetStep: "2",
        userEmail: email,
        verificationKey: match.params.token,
        newPassword: password,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //TODO first check if response returns true then hit the callback method
        this.setState({ passwordResetComplete: true, newMember: false });
      },
      onError: (err) => {
        this.setState({ isForgotPasswordStep2Called: false });
        if (err.errors != null && err.errors[0] != null) {
          alert(err.errors[0].message);
        }
        console.error(err);
      },
    });
  }

  render() {
    const { loginPopUpType, handleForgotPasswordStep2PopUp } = this.props;
    const {
      newMember,
      email,
      matchPassword,
      isForgotPasswordStep2Called,
      passwordResetComplete,
    } = this.state;
    return (
      <div
        className={loginPopUpType === "Action" ? "wer" : "wer"}
        style={{ backgroundColor: "#a2a2a2de" }}
      >
        <div
          className={
            loginPopUpType === "Action"
              ? "container  loginPop1"
              : "container  loginwer1 "
          }
          style={{
            maxWidth: "100%",
            top: 40,
            height: "100%",
            paddingBottom: 80,
          }}
        >
          <div className="main-content login-before" style={{ height: "100%" }}>
            <div
              className="signup-content text-center"
              style={{ height: "100%", width: "100%" }}
            >
              <div
                style={{
                  display: newMember ? "block" : "none",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer onesignupWidth"
                  }
                >
                  <div
                    onClick={handleForgotPasswordStep2PopUp}
                    className="close-arrow"
                    style={{ top: 15, right: 10, cursor: "pointer" }}
                  >
                    CLOSE
                    <img
                      id="close"
                      src={require("../../images/closeCross.svg")}
                      style={{ width: 12, marginLeft: 8, marginTop: -2 }}
                      alt=""
                    />
                  </div>

                  <div className="col-md-12" style={{ paddingBottom: 20 }}>
                    <img
                      id="logos"
                      src={require("../../images/logo1.svg")}
                      style={{ width: 263.37 }}
                      alt=""
                    />
                  </div>

                  <div className="col-md-12" style={{ padding: 0 }}>
                    <h2 className="form-title" style={{ marginBottom: "20%" }}>
                      Password Reset
                    </h2>
                  </div>

                  <div className="col-md-12" style={{ padding: 0 }}>
                    <h3 className="form-title">
                      Please enter new password below:
                    </h3>
                  </div>

                  <div className="signup-input-group">
                    <div className="newfrom mb-3 pl-4">
                      <input
                        type="email"
                        className="form-input"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        readOnly
                      />
                    </div>
                    <div style={{ width: 25 }}>
                      {email ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            style={{ width: 25 }}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="signup-input-group">
                    <div className="newfrom mb-3 pl-4">
                      <input
                        type="password"
                        className="form-input"
                        name="password"
                        id="password"
                        placeholder="New Password"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                    </div>
                    <div style={{ width: 25 }}>
                      {matchPassword ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            style={{ width: 25 }}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="signup-input-group">
                    <div className="newfrom mb-3 pl-4">
                      <input
                        type="password"
                        className="form-input"
                        name="cpassword"
                        id="cpassword"
                        placeholder="Confirm New Password"
                        onChange={(e) => {
                          this.handleClick(e);
                        }}
                      />
                    </div>
                    <div style={{ width: 25 }}>
                      {matchPassword ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            style={{ width: 25 }}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="signup-input-group">
                    <div className="mb-3">
                      {matchPassword &&
                      isForgotPasswordStep2Called === false ? (
                        <input
                          type="button"
                          name="submit"
                          id="submit1"
                          className="form-submit submit-click"
                          value="LOG IN"
                          onClick={(event) => {
                            this.forgotPassword(event);
                          }}
                        />
                      ) : (
                        <input
                          type="button"
                          name="submit"
                          id="submit1"
                          className="form-submit  submit-btn disabled"
                          value="LOG IN"
                        />
                      )}
                    </div>
                  </div>
                </form>

                <div className="warnings Signupwer">
                  <p>
                    By clicking above, you agree to the{" "}
                    <a
                      href="https://demo.broadstreet.io/legal/terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                    <span> and </span>
                    <a
                      href="https://demo.broadstreet.io/legal/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    . BroadStreet may send emails and the BroadStreet platform
                    does use Cookies.
                  </p>
                </div>
              </div>
              {/* Sign up popup */}

              {/* BroadStreet popup */}

              <div
                style={{
                  display: passwordResetComplete ? "block" : "none",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer signupWidth"
                  }
                >
                  <div
                    onClick={handleForgotPasswordStep2PopUp}
                    className="close-arrow"
                    style={{ top: 15, right: 10, cursor: "pointer" }}
                  >
                    CLOSE
                    <img
                      id="close"
                      src={require("../../images/closeCross.svg")}
                      style={{ width: 12, marginLeft: 8, marginTop: -2 }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-12" style={{ paddingBottom: 20 }}>
                    <img
                      id="logos"
                      src={require("../../images/logo1.svg")}
                      style={{ width: 263.37 }}
                      alt=""
                    />
                  </div>

                  <div
                    className="col-md-12"
                    style={{ padding: 0, marginBottom: "20%" }}
                  >
                    <h2 className="form-title">Password Reset Complete</h2>
                  </div>

                  <div className="col-md-12">
                    <p>You can get started!</p>
                    <p>Thanks again for visiting BroadStreet.</p>
                  </div>

                  <div
                    className="col-md-12"
                    style={{ padding: 0, paddingTop: 30, paddingBottom: 5 }}
                  >
                    <input
                      type="button"
                      name="submit"
                      id="submit2"
                      className="form-submit submit-click "
                      value="Continue Browsing"
                      style={{ width: 272 }}
                      onClick={handleForgotPasswordStep2PopUp}
                    />
                  </div>
                </form>
              </div>
              {/* BroadStreet popup */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordPopUpStep2;
