import React, { Component } from "react";
import "../../css/components/SignInSignUpForgotPassword/SignInSignUpForgotPassword.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";
import "../../css/custom.css";

class LoginPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isForgotPasswordPopUpVisible: false,
      isSignupPopUpVisible: false,
      isLoginFormSubmitted: false,
      userName: "",
      password: "",
      LoginPopUp: true,
      loginFailed: false,
      LoginError: "",
      isUserNameValid: false,
      isPasswordValid: false,
      isBadPassword: false,
      isBadEmail: false,
    };
  }

  componentDidMount() {
    if (window.gapi != null) {
      window.gapi.load("auth2", () => {
        window.gapi.auth2
          .init({
            client_id:
              "733542641252-rb5iqhqusou1nq8r6kq82rq7ri1top9u.apps.googleusercontent.com",
          })
          .then(
            () => {
              window.gapi.signin2.render("g-signin2", {
                width: 190,
                height: 40,
                longtitle: true,
                theme: "dark",
                onsuccess: this.onGoogleSignIn,
              });
              console.log("google signin hook registered");
            },
            (error) => {
              console.error(error);
            }
          );
      });
    }
    setTimeout(() => {
      const userName = document.querySelector("#userName");
      if (userName) {
        const style = window.getComputedStyle(userName);
        if (style && style.backgroundColor !== "rgb(255, 255, 255)") {
          this.setState({ isAutoFill: true });
        }
      }
    }, 1000);
  }

  handleUsername(e) {
    let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$\b$/i;

    if (pattern.test(e.target.value)) {
      this.setState({ userName: e.target.value, isUserNameValid: true });
    } else {
      this.setState({ userName: e.target.value, isUserNameValid: false });
    }
  }

  handlePassword(e) {
    //let pattern = /^(?=.*\d)(?=.*[a-zA-Z]).{6,20}\b$/i
    //var valid = pattern.test(e.target.value)
    var valid = e.target.value !== "";
    this.setState({ password: e.target.value, isPasswordValid: valid });
  }

  handleLoginProblem = (validationErrors, userName) => {
    const { handleLoginProblem } = this.props;
    var errorKey = null;
    if (validationErrors != null && validationErrors[0] != null) {
      errorKey = validationErrors[0].key;
    }

    if (errorKey === "loginFailed") {
      /* existing user but wrong password */
      /* show forgot password popup version 1 (pre-populated) */
      this.setState({ isBadPassword: true, isLoginFormSubmitted: false });
      return;
    } else if (errorKey === "userDoesNotExist") {
      this.setState({ isBadEmail: true, isLoginFormSubmitted: false });
      return;
    }

    handleLoginProblem(validationErrors, userName);
  };

  loginUserApi(event) {
    const {
      onLogin,
      location,
      loginPopUpType,
      onUpdateTheTopLeftMenu,
      handleLoginPopUp,
    } = this.props;
    const { password, userName } = this.state;
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      isLoginFormSubmitted: true,
      isBadPassword: false,
      isBadEmail: false,
    });

    const mutation = graphql`
      mutation LoginPopUpMutation($input: LoginUserInput!) {
        loginUser(input: $input) {
          userAccountNode {
            id
          }
          success
          validationErrors {
            key
            message
          }
          clientMutationId
        }
      }
    `;
    const variables = {
      input: {
        username: userName,
        userPassword: password,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (
          response.loginUser.success === true &&
          response.loginUser.userAccountNode !== null
        ) {
          onLogin(response.loginUser.userAccountNode.id);
          if (
            !(
              (location.pathname.indexOf("/collection/") > -1 ||
                location.pathname.indexOf("/page/") > -1) &&
              loginPopUpType === "Action"
            )
          ) {
            onUpdateTheTopLeftMenu();
          }
          handleLoginPopUp(false);
        } else {
          this.handleLoginProblem(
            response.loginUser.validationErrors,
            userName
          );
        }
      },
      onError: (err) => {
        console.log("error", err);
        this.setState({ isLoginFormSubmitted: false });
        alert("Unexpected system error.  Please try again.");
      },
    });
  }

  onGoogleSignIn = (googleUser) => {
    var profile = googleUser.getBasicProfile();
    console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log("Name: " + profile.getName());
    console.log("Image URL: " + profile.getImageUrl());
    console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not
    // present.
    // Encrypted OAuth2 ID token to pass to backend
    var idToken = googleUser.getAuthResponse().id_token;
    this.loginGoogleUserApi(idToken, "google");
  };

  loginGoogleUserApi(idToken, tokenSource) {
    const {
      onLogin,
      location,
      loginPopUpType,
      onUpdateTheTopLeftMenu,
      handleLoginPopUp,
    } = this.props;
    const { userName } = this.state;
    this.setState({
      isLoginFormSubmitted: true,
      isBadPassword: false,
      isBadEmail: false,
    });

    const mutation = graphql`
      mutation LoginPopUpGoogleMutation($input: LoginUserInput!) {
        loginUser(input: $input) {
          userAccountNode {
            id
          }
          success
          validationErrors {
            key
            message
          }
          clientMutationId
        }
      }
    `;
    const variables = {
      input: {
        username: userName,
        oauth2Token: idToken,
        oauth2Source: tokenSource,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (
          response.loginUser.success === true &&
          response.loginUser.userAccountNode !== null
        ) {
          onLogin(response.loginUser.userAccountNode.id);
          if (
            !(
              (location.pathname.indexOf("/collection/") > -1 ||
                location.pathname.indexOf("/page/") > -1) &&
              loginPopUpType === "Action"
            )
          ) {
            onUpdateTheTopLeftMenu();
          }
          handleLoginPopUp(false);
        } else {
          this.handleLoginProblem(
            response.loginUser.validationErrors,
            userName
          );
          //this.setState({ isLoginFormSubmitted: false })
        }
      },
      onError: (err) => {
        console.log("error", err);
        this.setState({ isLoginFormSubmitted: false });
        alert("Unexpected system error.  Please try again.");
        /*
          this.setState({
              isLoginFormSubmitted: false, loginFailed: true, LoginPopUp: false
          })
          if (err.errors.length > 0) {
              this.setState({ LoginError: err.errors[0].message })
          }
          */
      },
    });
  }

  render() {
    const { loginPopUpType, openSignUpPopUp, openForgotPasswordPopUp } =
      this.props;
    const {
      LoginPopUp,
      loginFailed,
      LoginError,
      userName,
      isUserNameValid,
      password,
      isAutoFill,
      isBadEmail,
      isPasswordValid,
      isLoginFormSubmitted,
      isBadPassword,
    } = this.state;
    return (
      <div className="wer overlay">
        <div
          className={
            loginPopUpType === "Action"
              ? "container  loginwer1"
              : "container  loginwer1"
          }
        >
          <div className="main-content login-before">
            <div className="signup-content text-center">
              <div className={"h-100 " + (LoginPopUp ? "d-block" : "d-none")}>
                <form
                  method="POST"
                  id="signup-form"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form forgotWidth"
                      : "signup-form forgotWidth"
                  }
                >
                  <h3 className="form-title">Please sign in with email</h3>
                  <div className="signup-input-group">
                    <div className="mb-3 newfrom pl-4">
                      <input
                        type="text"
                        className="form-input"
                        name="userName"
                        id="userName"
                        placeholder="Email"
                        onChange={(e) => {
                          this.handleUsername(e);
                        }}
                        value={userName}
                      />
                    </div>
                    <div style={{ width: 25 }}>
                      {isUserNameValid ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="signup-input-group">
                    <div className="newfrom mb-3 pl-4">
                      <input
                        type="password"
                        className="form-input"
                        name="password"
                        id="password"
                        placeholder="Password"
                        onChange={(e) => {
                          this.handlePassword(e);
                        }}
                        value={password}
                      />
                    </div>
                    <div style={{ width: 25 }}>
                      {isPasswordValid ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>

                  <div className="signup-input-group">
                    <div className="mb-3">
                      {(isLoginFormSubmitted === false &&
                        isPasswordValid &&
                        userName) ||
                      isAutoFill ? (
                        <input
                          type="submit"
                          name="submit"
                          id="submit2"
                          className="form-submit submit-click"
                          value="SIGN IN"
                          onClick={(event) => this.loginUserApi(event)}
                        />
                      ) : (
                        <input
                          type="button"
                          name="submit"
                          id="submit2"
                          className="form-submit submit-btn disabled"
                          value="SIGN IN"
                        />
                      )}
                    </div>
                  </div>
                  <div className="signup-input-group or-divider mt-2 mb-2">
                    <hr />
                    or <hr />
                  </div>
                  <div className="signup-input-group mt-4 mb-3">
                    <div id="g-signin2" className="g-signin2">
                      {/* <button style={{height:50, width:190}} >Sign In with Google</button>*/}
                    </div>
                  </div>
                </form>

                <div className="central_content">
                  <div className="mem-forgot">
                    {isBadPassword === true ? (
                      <span className="wrong-password">
                        Wrong password. Try again or <br />
                        click Forgot Your Password to reset it.
                      </span>
                    ) : null}
                    {isBadEmail === true ? (
                      <span className="wrong-password">
                        Wrong email. Try again or <br />
                        click Sign Up to join.
                      </span>
                    ) : null}
                    <p className="membershp">
                      <a
                        href={"#login"}
                        tabIndex={0}
                        className="loginhere-link"
                        onClick={openSignUpPopUp}
                      >
                        Need a Membership? Sign Up
                      </a>
                    </p>

                    <a
                      href="#forgotPassword"
                      tabIndex={0}
                      onClick={openForgotPasswordPopUp}
                    >
                      Forgot Your Password?
                    </a>
                  </div>
                </div>

                <div className="warnings mt-4">
                  <p>
                    By clicking above, you agree to the{" "}
                    <a
                      href="https://demo.broadstreet.io/legal/terms/"
                      target="ccterms"
                    >
                      Terms of Use
                    </a>
                    <span> and </span>
                    <a
                      href="https://demo.broadstreet.io/legal/privacy/"
                      target="ccprivacy"
                    >
                      Privacy Policy
                    </a>
                    . BroadStreet
                  </p>
                  <p>
                    may send emails and the BroadStreet website does use
                    cookies.
                  </p>
                </div>
              </div>

              {/* BroadStreet popup */}

              <div className={"h-100 " + (loginFailed ? "d-block" : "d-none")}>
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer"
                  }
                >
                  <div className="col-md-12 p-0">
                    <h2 className="form-title" style={{ marginBottom: 5 }}>
                      Please try again
                    </h2>
                  </div>

                  <div className="col-md-12 font-style">
                    <p>{LoginError}</p>
                  </div>
                  <div
                    className="col-md-12"
                    style={{ padding: 0, paddingTop: 30, paddingBottom: 5 }}
                  >
                    <input
                      type="button"
                      name="submit"
                      id="submit"
                      className="form-group form-submit submit-btn"
                      value="Please Log In"
                      onClick={() => {
                        this.setState({
                          loginFailed: false,
                          LoginPopUp: true,
                          userName: "",
                          password: "",
                          isUserNameValid: false,
                          isPasswordValid: false,
                        });
                      }}
                    />
                  </div>
                </form>
              </div>
              {/* BroadStreet popup */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPopUp;
