import React, { Component } from "react";
import "../../css/components/SignInSignUpForgotPassword/SignInSignUpForgotPassword.css";
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../RelayEnvironment";

class ForgotPasswordPopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
      isLoginPopUpVisible: false,
      newMember: true,
      communityCommonsPopupVisible: false,
      isSignupPopUpVisible: false,
      handleForgotPasswordPopUp: false,
      isEmailValid: false,
      isForgotPasswordCalled: false,
    };
  }

  handleClick(e) {
    let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$\b$/i;

    if (pattern.test(e.target.value)) {
      this.setState({ email: e.target.value, isEmailValid: true });
    } else {
      this.setState({ email: e.target.value, isEmailValid: false });
    }
  }

  SignUpStep1(event) {
    const { email } = this.state;
    event.preventDefault();

    this.setState({ isForgotPasswordCalled: true });

    const mutation = graphql`
      mutation ForgotPasswordPopUpLegacyUserMutation(
        $input: CreateVerifyEmailRequestInput!
      ) {
        createVerifyEmail(input: $input) {
          verifyEmailRequestNode {
            signupStep
            userEmail
          }
          success
          validationErrors {
            key
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        userEmail: email,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //TODO first check if response returns true then hit the callback method
        if (response.createVerifyEmail.success === true) {
          this.setState({
            thankYouPopup: false,
            communityCommonsPopupVisible: true,
            newMember: false,
          });
        } else {
          this.setState({ isForgotPasswordCalled: false });
          alert(response.createVerifyEmail.validationErrors[0].message);
        }
      },
      onError: (err) => {
        console.error(err);
        this.setState({ isForgotPasswordCalled: false });
        alert("Unexpected system error. Please try again.");
      },
    });
  }

  forgotPassword(event) {
    const { email } = this.state;
    const { isLegacyUser } = this.props;
    if (isLegacyUser === true) {
      this.SignUpStep1(event);
      return;
    }
    event.preventDefault();
    this.setState({ isForgotPasswordCalled: true });

    const mutation = graphql`
      mutation ForgotPasswordPopUpMutation(
        $input: CreatePasswordResetRequestInput!
      ) {
        createPasswordReset(input: $input) {
          passwordResetRequestNode {
            resetStep
            userEmail
          }
          success
          validationErrors {
            key
            message
          }
        }
      }
    `;

    const variables = {
      input: {
        userEmail: email,
      },
    };

    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        //TODO first check if response returns true then hit the callback method
        // console.log(response)
        if (response.createPasswordReset.success === true) {
          this.setState({
            thankYouPopup: false,
            communityCommonsPopupVisible: true,
            newMember: false,
          });
        } else {
          this.setState({ isForgotPasswordCalled: false });
          alert(response.createPasswordReset.validationErrors[0].message);
        }
      },
      onError: (err) => {
        this.setState({ isForgotPasswordCalled: false });
        alert("Unexpected system error. Please try again.");
      },
    });
  }

  render() {
    const {
      newMember,
      isEmailValid,
      isForgotPasswordCalled,
      communityCommonsPopupVisible,
    } = this.state;
    const {
      loginPopUpType,
      isLegacyUser,
      openSignUpPopUp,
      openLoginPopUp,
      handleForgotPasswordPopUp,
    } = this.props;
    return (
      <div className="wer overlay">
        <div className="container loginwer1">
          <div className="main-content login-before h-100">
            <div className="signup-content text-center">
              <div className={"h-100 " + (newMember ? "d-block" : "d-none")}>
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer onesignupWidth forgotWidth"
                  }
                >
                  <div style={{ marginBottom: "10%" }}>
                    <div className="col-md-12 p-0">
                      <h3 className="form-title" style={{ marginBottom: 5 }}>
                        Password Reset
                      </h3>
                    </div>
                    <div className="col-md-12 px-0 pt-4 pb-2">
                      <h4 className="forgotP">
                        Just enter the email address associated with your{" "}
                        {isLegacyUser ? "old" : null} account and we will send
                        you an email with instructions on how to reset your
                        password.
                      </h4>
                    </div>
                  </div>

                  <div className="signup-input-group">
                    <div className="mb-3 newfrom pl-4">
                      <input
                        type="email"
                        className="form-input"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        onChange={(e) => {
                          this.handleClick(e);
                        }}
                      />
                    </div>
                    <div style={{ width: 25 }}>
                      {isEmailValid ? (
                        <span className="validcheck">
                          <img
                            src={require("../../images/checkMark.svg")}
                            alt=""
                          />
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="signup-input-group">
                    {isEmailValid && isForgotPasswordCalled === false ? (
                      <div className="mb-3">
                        <input
                          type="submit"
                          name="submit"
                          id="submit"
                          className="form-submit submit-click"
                          value="Send"
                          onClick={(event) => {
                            this.forgotPassword(event);
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <input
                          type="button"
                          name="submit"
                          id="submit"
                          className="form-submit  submit-btn disabled"
                          value="Send"
                        />
                      </div>
                    )}
                  </div>
                </form>

                <div className="central_content">
                  <div className="mem-forgot">
                    <p className="membershp">
                      <a
                        href="#login"
                        tabIndex={0}
                        className="loginhere-link"
                        onClick={openSignUpPopUp}
                      >
                        Need a Membership? Sign up
                      </a>
                    </p>
                    <a href="#pass" tabIndex={0} onClick={openLoginPopUp}>
                      Remember Your Password?
                    </a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="warnings">
                    <p>
                      By clicking above, you agree to the{" "}
                      <a
                        href="https://demo.broadstreet.io/legal/terms/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                      <span> and </span>
                      <a
                        href="https://demo.broadstreet.io/legal/privacy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <p>
                      BroadStreet may send emails and the BroadStreet platform
                      does use Cookies.
                    </p>
                  </div>
                </div>
              </div>
              {/* Sign up popup */}

              {/* BroadStreet popup */}

              <div
                className={
                  "h-100 " +
                  (communityCommonsPopupVisible ? "d-block" : "d-none")
                }
              >
                <form
                  action="#"
                  className={
                    loginPopUpType === "Action"
                      ? "signup-form"
                      : "signup-form  sign-wer onesignupWidth forgotWidth"
                  }
                  style={{ marginTop: "30%" }}
                >
                  <div className="col-md-12 p-0">
                    <h2 className="form-title" style={{ marginBottom: 5 }}>
                      Please check your inbox
                    </h2>
                  </div>

                  <div className="col-md-12 font-style">
                    <p>
                      Once you verify your email address, you can get started!
                    </p>
                    <p>Thanks again for visiting BroadStreet.</p>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="submit"
                      name="submit"
                      id="submit2"
                      className="form-group form-submit submit-btn thank-you-continue-btn"
                      value="Continue Browsing"
                      onClick={handleForgotPasswordPopUp}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordPopUp;
